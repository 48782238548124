<script setup>
    import Cookies from 'js-cookie';

    import JetButton from './JetButton.vue';
    import JetIcon from './JetIcon.vue';
    import JetLink from '@jetDS/components/JetLink.vue';
    import JetSkeleton from '@jetDS/components/JetSkeleton.vue';

    const props = defineProps({
        iconName: {
            type: String,
            default: null,
        },
        type: {
            type: String,
            default: 'default',
            validator(value) {
                return ['default', 'info', 'success', 'warning', 'error'].includes(value);
            },
        },
        plain: {
            type: Boolean,
            default: false,
        },
        actionLabel: {
            type: String,
            default: null,
        },
        actionCallable: {
            type: Function,
            default: () => {},
        },
        actionMinWidth: {
            type: String,
            default: '150px',
        },
        actionLinkProps: {
            type: Object,
            default: () => ({}),
        },
        closable: {
            type: Boolean,
            default: false,
        },
        visibilityCookieDaysExpires: {
            type: Number,
            default: null,
        },
        visibilityCookieName: {
            type: String,
            default: null,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    });

    const emit = defineEmits(['close']);

    const getIconName = computed(() => {
        if (props.iconName) {
            return props.iconName;
        }

        return {
            default: 'circle-info',
            info: 'circle-info',
            success: 'circle-check',
            warning: 'triangle-exclamation',
            error: 'circle-exclamation',
        }[props.type];
    });

    const isVisible = ref(false);

    const hasActionLinkProps = computed(() => Object.keys(props.actionLinkProps).length > 0);

    watch(
        () => props.visibilityCookieName,
        () => {
            isVisible.value = !Cookies.get(props.visibilityCookieName);
        },
        {immediate: true},
    );

    function onClose() {
        isVisible.value = false;
        const cookieOptions = props.visibilityCookieDaysExpires ? {expires: props.visibilityCookieDaysExpires} : null;
        Cookies.set(props.visibilityCookieName, '1', cookieOptions);
        emit('close');
    }
</script>

<template>
    <div
        v-if="isVisible && !loading"
        class="jet-callout base py-3 px-5"
        :class="{
            'jet-callout--default': type === 'default',
            'jet-callout--info': type === 'info',
            'jet-callout--success': type === 'success',
            'jet-callout--warning': type === 'warning',
            'jet-callout--error': type === 'error',
            'jet-callout--plain': plain,
        }">
        <div class="jet-callout__icon mr-2">
            <JetIcon :name="getIconName" icon-style="regular" />
        </div>
        <div class="jet-callout__text mr-4 medium">
            <slot />
        </div>
        <div
            v-if="actionLabel && !hasActionLinkProps"
            class="jet-callout__action ml-auto semibold"
            @click="actionCallable()">
            {{ actionLabel }}
        </div>
        <div v-else-if="hasActionLinkProps" class="jet-callout__action ml-auto semibold">
            <JetLink
                :href="actionLinkProps?.href"
                :new-tab="actionLinkProps?.newTab"
                :to-route="actionLinkProps?.toRoute"
                :show-arrow="actionLinkProps?.showArrow">
                {{ actionLabel }}
            </JetLink>
        </div>
        <JetButton v-if="closable" icon-name="times" icon-style="regular" link size="small" @click="onClose" />
    </div>
    <JetSkeleton v-else-if="isVisible && loading" kind="callout" />
</template>

<style scoped lang="scss">
    @use '@jetDS/scss/_media-queries.scss' as *;

    .jet-callout {
        border-radius: 4px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        @include media-query('md-and-up') {
            flex-wrap: nowrap;
        }

        &__icon {
            &,
            svg {
                width: 20px;
                height: 20px;
            }
        }

        &__text {
            flex-grow: 1;
            text-align: start;
        }

        &__action {
            min-width: v-bind(actionMinWidth);
            text-align: end;
            vertical-align: middle;
            cursor: pointer;
        }
    }

    .jet-callout--default {
        background-color: var(--el-color-primary);
        color: var(--el-color-white);

        &.jet-callout--plain {
            background-color: var(--el-color-primary-light-9);
            color: var(--el-color-black);
        }
    }
    .jet-callout--info {
        background-color: var(--jet-color-blue);
        color: var(--el-color-white);

        &.jet-callout--plain {
            background-color: var(--jet-color-blue-light-9);
            color: var(--el-color-black);

            .jet-callout__icon {
                color: var(--jet-color-blue);
            }
        }
    }
    .jet-callout--success {
        background-color: var(--el-color-success);
        color: var(--el-color-white);

        &.jet-callout--plain {
            background-color: var(--el-color-success-light-9);
            color: var(--el-color-black);

            .jet-callout__icon {
                color: var(--el-color-success);
            }
        }
    }
    .jet-callout--warning {
        background-color: var(--el-color-warning);
        color: var(--el-color-white);

        &.jet-callout--plain {
            background-color: var(--el-color-warning-light-9);
            color: var(--el-color-black);

            .jet-callout__icon {
                color: var(--el-color-warning);
            }
        }
    }
    .jet-callout--error {
        background-color: var(--el-color-danger);
        color: var(--el-color-white);

        &.jet-callout--plain {
            background-color: var(--el-color-danger-light-9);
            color: var(--el-color-black);

            .jet-callout__icon {
                color: var(--el-color-danger);
            }
        }
    }
</style>
