<script setup>
    import {addressFormRules} from '@jetCommon/helpers/validators.js';
    import {ref} from 'vue';

    import AddressInput from '@jetCommon/components/inputs/AddressInput.vue';
    import JetDatePicker from '@jetDS/components/JetDatePicker.vue';
    import JetForm from '@jetDS/components/JetForm.vue';
    import JetFormItem from '@jetDS/components/JetFormItem.vue';
    import JetInfoLine from '@jetDS/components/JetInfoLine.vue';
    import JetInfoLineGroup from '@jetDS/components/JetInfoLineGroup.vue';
    import JetInput from '@jetDS/components/JetInput.vue';

    const props = defineProps({
        policy: {
            type: Object,
            required: true,
        },
    });

    const emit = defineEmits(['updated-form']);

    const formRef = ref(null);
    const formData = reactive({
        name: props.policy.name,
        patron_saint: props.policy.patron_saint || null,
        additional_holiday: props.policy.additional_holiday || null,
        address: props.policy.address || {
            country: 'IT',
        },
    });

    watch(
        formData,
        () => {
            emit('updated-form', formData);
        },
        {deep: true},
    );

    const formRules = {
        name: [{required: true, message: 'Inserire il nome della policy', trigger: 'change'}],
        patron_saint: [{required: true, message: 'Inserire una data', trigger: 'change'}],
        additional_holiday: [{required: false, trigger: 'change'}],
        address: addressFormRules,
    };

    function isFormValid() {
        return formRef.value.getElFormRef().validate();
    }

    defineExpose({
        isFormValid,
    });
</script>

<template>
    <JetInfoLineGroup>
        <JetForm ref="formRef" :rules="formRules" :model="formData" label-position="top">
            <JetInfoLine label="Nome della sede" action-label="">
                <JetFormItem prop="name">
                    <JetInput v-model="formData.name" class="TextInput"></JetInput>
                </JetFormItem>
            </JetInfoLine>
            <JetInfoLine label="Indirizzo" action-label="">
                <AddressInput v-model="formData.address" props-prefix="address" />
            </JetInfoLine>
            <JetInfoLine label="Santo patrono" action-label="">
                <JetFormItem prop="patron_saint" help-text="L'anno di questa data viene ignorato">
                    <JetDatePicker v-model="formData.patron_saint" placeholder="Scegli il giorno" size="large" />
                </JetFormItem>
            </JetInfoLine>
            <JetInfoLine label="Festività aggiuntiva" action-label="">
                <JetFormItem prop="additional_holiday" help-text="L'anno di questa data viene ignorato">
                    <JetDatePicker v-model="formData.additional_holiday" placeholder="Scegli il giorno" size="large" />
                </JetFormItem>
            </JetInfoLine>
        </JetForm>
    </JetInfoLineGroup>
</template>

<style scoped lang="scss">
    .TextInput {
        max-width: 320px;
    }

    :deep(.el-form-item__error) {
        position: absolute;
    }

    // Address
    .form-flex-block {
        display: flex;
        flex-wrap: nowrap;

        &__item-half {
            flex: 1 1 auto;

            &:not(:last-child) {
                margin-right: 16px;
            }
        }
    }
</style>
