<script setup>
    import {MINUTE_CHOICES} from '@/features/company-policy/clocking/constants';
    import {sumHourStringAndMinutes} from '@jetCommon/helpers/date';

    import JetButton from '@jetDS/components/JetButton.vue';
    import JetCallout from '@jetDS/components/JetCallout.vue';
    import JetDialog from '@jetDS/components/JetDialog.vue';
    import JetFormItem from '@jetDS/components/JetFormItem.vue';
    import JetSelect from '@jetDS/components/JetSelect.vue';
    import JetSwitch from '@jetDS/components/JetSwitch.vue';

    const props = defineProps({
        clockingConfig: {
            type: Object,
            required: true,
        },
    });

    const emit = defineEmits(['update:clockingAnomalyConfigData', 'update:showDialog']);

    const dialogRef = ref(null);

    const dialogData = ref({
        ...props.clockingConfig,
    });

    function onConfirm() {
        emit('update:clockingAnomalyConfigData', dialogData.value);
        dialogRef.value.closeDialog();
    }

    function computeExampleCalloutByType(earlyEntranceMinutes, lateEntranceMinutes, clockingType) {
        const computeCallout = (prefix, suffix, hour) => {
            if (earlyEntranceMinutes && lateEntranceMinutes) {
                return (
                    `${prefix} tra le ${sumHourStringAndMinutes(hour, -earlyEntranceMinutes)} e le ` +
                    `${sumHourStringAndMinutes(hour, lateEntranceMinutes)} ${suffix}`
                );
            }
            if (earlyEntranceMinutes) {
                return `${prefix} dalle ${sumHourStringAndMinutes(hour, -earlyEntranceMinutes)} in avanti ${suffix}`;
            }
            if (lateEntranceMinutes) {
                return `${prefix} fino alle ${sumHourStringAndMinutes(hour, lateEntranceMinutes)} ${suffix}`;
            }
            return `${prefix} in qualsiasi momento ${suffix}`;
        };

        const suffix = "senza che il sistema segnali un'anomalia.";

        if (clockingType === 'entrance') {
            const prefix =
                "Ipotizzando un orario di lavoro con inizio alle 09:00 il dipendente potrà timbrare l'ingresso";
            return computeCallout(prefix, suffix, '09:00');
        }
        if (clockingType === 'exit') {
            const prefix =
                "Ipotizzando un orario di lavoro con uscita alle 18:00 il dipendente potrà timbrare l'uscita";
            return computeCallout(prefix, suffix, '18:00');
        }
        if (clockingType === 'start_break') {
            const prefix =
                'Ipotizzando un orario di lavoro con una pausa che inizia alle 13:00 ' +
                "il dipendente potrà timbrare l'inizio della pausa";
            return computeCallout(prefix, suffix, '13:00');
        }

        if (clockingType === 'end_break') {
            const prefix =
                'Ipotizzando un orario di lavoro con una pausa che finisce alle ' +
                '14:00 il dipendente potrà timbrare la fine della pausa';
            return computeCallout(prefix, suffix, '14:00');
        }
    }

    watch(
        () => dialogData.value,
        () => {
            if (!dialogData.value.entrance_clocking_enabled) {
                dialogData.value.exit_clocking_enabled = false;
                dialogData.value.intermediate_clockings_enabled = false;
            }
            if (!dialogData.value.exit_clocking_enabled) {
                dialogData.value.intermediate_clockings_enabled = false;
            }
        },
        {deep: true},
    );
</script>

<template>
    <JetDialog
        ref="dialogRef"
        show
        title="Timbrature richieste e anomalie"
        destroy-on-close
        @update:show="emit('update:showDialog', $event)">
        <template #body>
            <div class="SettingsContainer">
                <section class="SettingsContainer_Item">
                    <div class="SwitchContainer">
                        <p>Richiedi timbratura di ingresso</p>
                        <JetSwitch v-model="dialogData.entrance_clocking_enabled" />
                    </div>
                    <template v-if="dialogData.entrance_clocking_enabled">
                        <div class="SelectContainer">
                            <JetFormItem label="Tolleranza per anticipo">
                                <JetSelect
                                    v-model="dialogData.allowed_early_entrance_minutes"
                                    :options="MINUTE_CHOICES" />
                            </JetFormItem>
                            <JetFormItem label="Tolleranza per ritardo">
                                <JetSelect
                                    v-model="dialogData.allowed_late_entrance_minutes"
                                    :options="MINUTE_CHOICES" />
                            </JetFormItem>
                        </div>
                        <JetCallout plain type="info">
                            Esempio
                            <p class="sm">
                                {{
                                    computeExampleCalloutByType(
                                        dialogData.allowed_early_entrance_minutes,
                                        dialogData.allowed_late_entrance_minutes,
                                        'entrance',
                                    )
                                }}
                            </p>
                        </JetCallout>
                    </template>
                </section>
                <section class="SettingsContainer_Item">
                    <div class="SwitchContainer">
                        <p>Richiedi timbratura di uscita</p>
                        <JetSwitch
                            v-model="dialogData.exit_clocking_enabled"
                            :disabled="!dialogData.entrance_clocking_enabled" />
                    </div>
                    <template v-if="dialogData.exit_clocking_enabled">
                        <div class="SelectContainer">
                            <JetFormItem label="Tolleranza per anticipo">
                                <JetSelect v-model="dialogData.allowed_early_exit_minutes" :options="MINUTE_CHOICES" />
                            </JetFormItem>
                            <JetFormItem label="Tolleranza per ritardo">
                                <JetSelect v-model="dialogData.allowed_late_exit_minutes" :options="MINUTE_CHOICES" />
                            </JetFormItem>
                        </div>
                        <JetCallout plain type="info">
                            Esempio
                            <p class="sm">
                                {{
                                    computeExampleCalloutByType(
                                        dialogData.allowed_early_exit_minutes,
                                        dialogData.allowed_late_exit_minutes,
                                        'exit',
                                    )
                                }}
                            </p>
                        </JetCallout>
                    </template>
                </section>
                <section class="SettingsContainer_Item">
                    <div class="SwitchContainer">
                        <p>Richiedi inizio timbrature intermedie (pause e assenze)</p>
                        <JetSwitch
                            v-model="dialogData.intermediate_clockings_enabled"
                            :disabled="!dialogData.exit_clocking_enabled" />
                    </div>
                    <template v-if="dialogData.intermediate_clockings_enabled">
                        <div class="SelectContainer">
                            <JetFormItem label="Tolleranza per anticipo">
                                <JetSelect
                                    v-model="dialogData.allowed_early_start_intermediate_clocking_minutes"
                                    :options="MINUTE_CHOICES" />
                            </JetFormItem>
                            <JetFormItem label="Tolleranza per ritardo">
                                <JetSelect
                                    v-model="dialogData.allowed_late_start_intermediate_clocking_minutes"
                                    :options="MINUTE_CHOICES" />
                            </JetFormItem>
                        </div>
                        <JetCallout plain type="info">
                            Esempio
                            <p class="sm">
                                {{
                                    computeExampleCalloutByType(
                                        dialogData.allowed_early_start_intermediate_clocking_minutes,
                                        dialogData.allowed_late_start_intermediate_clocking_minutes,
                                        'start_break',
                                    )
                                }}
                            </p>
                        </JetCallout>
                    </template>
                </section>
                <section v-if="dialogData.intermediate_clockings_enabled" class="SettingsContainer_Item">
                    <div class="SwitchContainer">
                        <p>Richiedi inizio timbrature intermedie (pause e assenze)</p>
                        <JetSwitch :value="true" disabled />
                    </div>
                    <div class="SelectContainer">
                        <JetFormItem label="Tolleranza per anticipo">
                            <JetSelect
                                v-model="dialogData.allowed_early_end_intermediate_clocking_minutes"
                                :options="MINUTE_CHOICES" />
                        </JetFormItem>
                        <JetFormItem label="Tolleranza per ritardo">
                            <JetSelect
                                v-model="dialogData.allowed_late_end_intermediate_clocking_minutes"
                                :options="MINUTE_CHOICES" />
                        </JetFormItem>
                    </div>
                    <JetCallout plain type="info">
                        Esempio
                        <p class="CalloutContent">
                            {{
                                computeExampleCalloutByType(
                                    dialogData.allowed_early_end_intermediate_clocking_minutes,
                                    dialogData.allowed_late_end_intermediate_clocking_minutes,
                                    'end_break',
                                )
                            }}
                        </p>
                    </JetCallout>
                </section>
            </div>
        </template>
        <template #footer>
            <JetButton @click="$refs.dialogRef.closeDialog()">Annulla</JetButton>
            <JetButton type="primary" @click="onConfirm">Conferma</JetButton>
        </template>
    </JetDialog>
</template>

<style scoped lang="scss">
    .SettingsContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 16px;

        &_Item {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }
    }

    .SwitchContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
    }

    .SelectContainer {
        display: flex;
        justify-content: space-between;
        gap: 8px;
        align-items: center;
    }
</style>
