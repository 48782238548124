<script setup>
    import JetCurrencyInput from '@jetDS/components/JetCurrencyInput.vue';
    import JetForm from '@jetDS/components/JetForm.vue';
    import JetFormItem from '@jetDS/components/JetFormItem.vue';
    import JetInfoLine from '@jetDS/components/JetInfoLine.vue';
    import JetInfoLineGroup from '@jetDS/components/JetInfoLineGroup.vue';
    import JetInput from '@jetDS/components/JetInput.vue';

    const props = defineProps({
        policy: {
            type: Object,
            required: true,
        },
    });

    const emit = defineEmits(['updated-form']);

    const formRef = ref(null);
    const formData = ref({
        region_amount: '',
        italy_amount: '',
        ue_amount: '',
        extra_ue_amount: '',
        ...props.policy,
    });

    watch(
        formData,
        () => {
            emit('updated-form', formData.value);
        },
        {deep: true},
    );

    const formRules = {
        name: [{required: true, message: 'Inserire il titolo della policy', trigger: 'change'}],
        region_amount: [{required: true, message: 'Inserire un valore', trigger: 'change'}],
        italy_amount: [{required: true, message: 'Inserire un valore', trigger: 'change'}],
        ue_amount: [{required: true, message: 'Inserire un valore', trigger: 'change'}],
        extra_ue_amount: [{required: true, message: 'Inserire un valore', trigger: 'change'}],
    };

    function isFormValid() {
        return formRef.value.getElFormRef().validate();
    }

    defineExpose({
        isFormValid,
    });
</script>

<template>
    <JetInfoLineGroup>
        <JetForm ref="formRef" :rules="formRules" :model="formData" label-position="top">
            <JetInfoLine
                label="Titolo della policy"
                action-label=""
                description="Assegna un titolo alla policy per distinguerla facilmente dalle altre">
                <JetFormItem prop="name">
                    <JetInput v-model="formData.name" class="TextInput" />
                </JetFormItem>
            </JetInfoLine>
            <JetInfoLine
                label="Importo diaria per giorno di trasferta in regione"
                action-label=""
                description="Indica l'importo riconosciuto per ogni giorno di trasferta trascorso entro i confini regionali">
                <JetFormItem prop="region_amount" help-text="Il limite esente è di 46,48 €">
                    <JetCurrencyInput v-model="formData.region_amount" class="TextInput" size="default" />
                </JetFormItem>
            </JetInfoLine>
            <JetInfoLine
                label="Importo diaria per giorno di trasferta in Italia"
                action-label=""
                description="Definisci l'importo riconosciuto per ogni giorno di trasferta trascorso entro i confini nazionali">
                <JetFormItem prop="italy_amount" help-text="Il limite esente è di 46,48 €">
                    <JetCurrencyInput v-model="formData.italy_amount" class="TextInput" size="default" />
                </JetFormItem>
            </JetInfoLine>
            <JetInfoLine
                label="Importo diaria per giorno di trasferta in Unione Europea"
                action-label=""
                description="Definisci l'importo riconosciuto per ogni giorno di trasferta trascorso in paesi dell'Unione Europea">
                <JetFormItem prop="ue_amount" help-text="Il limite esente è di 77,47 €">
                    <JetCurrencyInput v-model="formData.ue_amount" class="TextInput" size="default" />
                </JetFormItem>
            </JetInfoLine>
            <JetInfoLine
                label="Importo diaria per giorno di trasferta al di fuori dell'Unione Euorpea"
                action-label=""
                description="Definisci l'importo riconosciuto per ogni giorno di trasferta trascorso in paesi al di fuori dell'Unione Europea">
                <JetFormItem prop="extra_ue_amount" help-text="Il limite esente è di 77,47 €">
                    <JetCurrencyInput v-model="formData.extra_ue_amount" class="TextInput" size="default" />
                </JetFormItem>
            </JetInfoLine>
        </JetForm>
    </JetInfoLineGroup>
</template>

<style scoped lang="scss">
    .TextInput {
        max-width: 320px;
    }
    :deep(.el-form-item__error) {
        position: absolute;
    }
</style>
