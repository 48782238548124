<script setup>
    defineProps({
        fullWidth: {type: Boolean, default: false},
        label: {type: String, default: null},
        value: {type: String, required: true},
        inputClass: {type: String, default: 'p-3 base medium'},
    });

    const slots = useSlots();
</script>

<template>
    <div>
        <span v-if="label" class="jet-fake-input-label mb-2 base medium">{{ label }}</span>
        <div class="jet-fake-input" :class="[{'jet-fake-input--fullwidth': fullWidth}, inputClass]">
            <div v-if="slots.prefix" class="jet-fake-input__prefix pr-3">
                <slot name="prefix" />
            </div>
            {{ value }}
            <div v-if="slots.suffix" class="jet-fake-input__suffix pl-3">
                <slot name="suffix" />
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
    .jet-fake-input-label {
        display: block;
    }

    .jet-fake-input {
        display: flex;
        align-items: center;
        background-color: var(--jet-fill-color-base);
        border-radius: var(--jet-input-border-radius);
        color: var(--jet-text-color-regular);

        &--fullwidth {
            width: 100%;
        }

        &__prefix {
            display: inline-flex;
            align-items: center;
            justify-content: center;

            :deep(svg) {
                width: 16px;
                height: 16px;
            }
        }

        &__suffix {
            display: inline-flex;
            align-items: center;
            justify-content: flex-end;
            flex-grow: 1;

            :deep(svg) {
                width: 16px;
                height: 16px;
            }
        }
    }
</style>
